import { useDeleteDriverMutation, useGetDriversQuery } from "./driverSlice";
import swal from "sweetalert";

const DriverExcerpt = ({ editCommunity, driverId, index }) => {

    const [DeleteDriverData] = useDeleteDriverMutation();
    
    const { driver } = useGetDriversQuery('getDrivers', {
        selectFromResult: ({ data }) => ({
            driver: data?.entities[driverId]
        }),
    })

    const deleteHandle = async (p_id) => {
        try {
            await DeleteDriverData(p_id).unwrap();
            swal("Done", "Data Deleted Successfully", "success")
        } catch (error) {
            swal("Error", "Failed to delete the  data", "")
        }
    };
    return (
        <>
            {/* responsive for pc size */}
            <tr key={index} className="mobile-hidden text-left fw-normal">
                <td>{index + 1}</td>
                <td>{driver?.p_name}</td>
                <td>{driver?.p_number}</td>
                <td>{driver?.p_email !== (null || '' || undefined) ? 'N/A' : (driver?.p_email)}</td>
                <td>
                    <p className={`rounded-circle ${driver?.is_leave === 0 ? 'bg-success' : 'bg-danger'}`}
                        style={{ height: "15px", width: "15px" }}>
                    </p>
                </td>
                <td>
                    <div className="dropdown">
                        <button
                            className="btn dropdown-toggle p-2"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{ border: 'none' }}
                        >
                            <i className="fas fa-ellipsis-v" style={{ color: 'gray' }}></i>
                        </button>
                        <ul className="dropdown_Box dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <div
                                    className="dropdown-item"
                                    onClick={() => editCommunity(driver?.p_id)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Edit
                                </div>
                                <div
                                    className="dropdown-item"
                                    onClick={() => deleteHandle(driver?.p_id)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Delete
                                </div>

                            </li>
                        </ul>
                    </div>
                </td>
            </tr>

            {/* responsive for mobile size */}
            <div key={index} className="Card_table_data">
                <div div className="Card_data">
                    <span className="Card_srNo">{index + 1}</span>
                </div>
                <div className="Card">
                    <div className="Card_data">
                        <span className="Card_srNo">Name :-</span>
                        <span className="Card_data_list">{driver?.p_name}</span>
                    </div>
                    <div className="Card_data">
                        <span className="Card_srNo">Number:-</span>
                        <span className="Card_data_list">{driver?.p_number}</span>
                    </div>
                </div>
                <div className="Card">
                    <div className="Card_data">
                        <span className="Card_srNo">Email :-</span>
                        <span className="Card_data_list">
                            {driver?.p_email !== (null || '' || undefined) ? 'N/A' : (driver?.p_email)}
                        </span>
                    </div>
                    <div className="Card_data">
                        <span className="Card_srNo">Leave :-</span>
                        <span className="Card_data_list">
                            <p className={`rounded-circle ${driver?.is_leave === 0 ? 'bg-success' : 'bg-danger'}  `} style={{ height: "15px", width: "15px" }}></p>
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DriverExcerpt