import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from '../features/api/apiSlice';
import authReducer from '../features/auth/authSlice';
import navReducer from '../features/auth/navSlice';
import usersListReducer from '../features/b2b_features/model/usersSlice'
import surveyPropertiesListReducer from "../features/b2g_features/models/surveyPropertiesListSlice";
// import b2gDriversReducer from '../features/mvvm/b2g/viewModel/driverB2g/driverB2gSlice'
import complainReducer from '../features/complain/ComplainSlice'
import collectionReducer from '../features/b2gDailyCollection/collectionSlice'
import DailyReportDatePageSlice from '../features/DailyReport/DailyReportDatePageSlice'
export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authReducer,
        usersList: usersListReducer,
        b2gProperties: surveyPropertiesListReducer,
        // b2gDrivers: b2gDriversReducer,
        complainB2g: complainReducer,
        collectionB2g: collectionReducer,
        DailyReportdateB2g:DailyReportDatePageSlice,
        nav: navReducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: true
})