// import B2GBreadcrumb from "../../components/breadcrumb/B2GBreadcrumb";
import SpinnerForPage from "../../components/spinner/SpinnerForPage";
// import { useEffect, useState } from "react";
import { useGetDailyReportDateQuery } from "./DailyReportSlice";
import { useNavigate } from "react-router-dom";
// import Pagination from "../../utils/Pagination";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setPageGlobal } from "./DailyReportDatePageSlice"

const DailyReportDate = () => {

    const navigate = useNavigate();

    // page global state
    const countPage = useSelector((state) => state.DailyReportdateB2g.page);
    const dispatch = useDispatch();

    const {
        data: dailyReportDateList,
        isLoading,
    } = useGetDailyReportDateQuery({ countPage })

    let totalPages = 1;
    if (dailyReportDateList && dailyReportDateList.entities) {
        totalPages = dailyReportDateList.total_pages;
    }
    return (
        <>
            {isLoading && <SpinnerForPage />}
            <div className="container-fluid mt-5">
                <div className="card card-dark">
                    <div className="table-border-style">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr className="text-left mobile-hidden">
                                        <th scope="col">Date</th>
                                        <th scope="col">Total Reports</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dailyReportDateList?.entities?.map((e, index) => {
                                        return (
                                            <>
                                                <tr key={index} className="mobile-hidden listData text-left fw-normal" onClick={() => { navigate(`/b2g/dailyreportList/${e?.dates}`) }}>
                                                    <td>{moment(e.dates).format("YYYY-MM-DD").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}</td>
                                                    <td>{e.total_marking}</td>
                                                </tr>
                                                <div key={index} className="Card_table_data" onClick={() => { navigate(`/b2g/dailyreportList/${e?.dates}`) }}>
                                                    <div div className="Card_data">
                                                        <span className="Card_srNo">{index + 1}</span>
                                                    </div>
                                                    <div className="Card">
                                                        <div className="Card_data">
                                                            <span className="Card_srNo">Date :-</span>
                                                            <span className="Card_data_list">{moment(e.dates).format("YYYY-MM-DD").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}</span>
                                                        </div>
                                                        <div className="Card_data">
                                                            <span className="Card_srNo">Total Reports :-</span>
                                                            <span className="Card_data_list">{e.total_marking}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="pagination_box fixed-bottom mt-1 mb-3">
                    <div className=" d-flex justify-content-center">
                        <div className="pagination">
                            <button className="page-link"
                                onClick={() => { dispatch(setPageGlobal(1)) }}
                            >
                                1
                            </button>
                            <button className="page-link d-flex"
                                disabled={countPage <= 1} onClick={() => { dispatch(setPageGlobal(countPage - 1)) }}
                            >
                                &laquo; Previous
                            </button>
                            <div className="page-link">{countPage}</div>
                            <button className="page-link"
                                disabled={countPage >= totalPages} onClick={() => { dispatch(setPageGlobal(countPage + 1)) }}
                            >
                                Next &raquo;
                            </button>
                            <button className="page-link"
                            //  onClick={lastPage}
                            >
                                {totalPages}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default DailyReportDate;