import { useComplainViewModel } from './ComplainController';
import { useDispatch } from 'react-redux';
import { logout } from '../auth/authSlice';
import { useState } from "react";
function ComplainList() {
    const {
        handleReset,
        handleChange,
        handleSearch,
        handleNavigateToDetail,
        complainList,
        isComplainListLoading,
        isComplainListError,
        complainListError,
        statusList,
        isStatusListError,
        statusListError,
        searchParamsLocal,
    } = useComplainViewModel();

    const dispatch = useDispatch()
    const [filterSearch, setFilterSearch] = useState({})

    if ((isComplainListError && complainListError?.statusCode === '401') || (isStatusListError && statusListError?.statusCode === '401')) {
        dispatch(logout)
    }

    return (
        <div className='container-fluid '>
            <div className='row'>
                <div className='col-3 p-3'></div>
            </div>
            <div className="Card_table_data w-25">
                <button type="button" className="Add_button bg-left-navabr w-100 text-light  btn btn-secondary" data-bs-toggle="offcanvas" data-bs-target="#depOffsearch" aria-controls="depOffsearch"> Search</button>

                <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="1" id="depOffsearch" aria-labelledby="depOffsearch">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvas1Label">Search</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ></button>
                    </div>
                    <select
                        aria-label="Default select example"
                        className=' form-select'
                        name='status'
                        onChange={handleChange}
                        value={searchParamsLocal.status}
                    >
                        <option value={""}>--</option>
                        {
                            statusList && statusList.length > 0 && statusList.map((item, index) => {
                                return <option key={index} value={item}>{item}</option>
                            })
                        }
                    </select>
                    <button className="bg-left-navabr mt-2 text-light  btn btn-secondary" onClick={handleSearch} data-bs-dismiss="offcanvas" aria-label="Close" >Data Search</button>
                    <button className="bg-left-navabr mt-2 text-light  btn btn-secondary" onClick={handleReset} data-bs-dismiss="offcanvas" aria-label="Close" >Reset</button>                </div>
            </div>
            <div className='mobile-hidden w-75 m-auto mb-3 row d-flex justify-content-center gap-5'>
                <div className="table_table_data w-100 d-flex justify-content-center gap-5">
                    <select
                        aria-label="Default select example"
                        className=' form-select'
                        name='status'
                        onChange={handleChange}
                        value={searchParamsLocal.status}
                    >
                        <option value={""}>--</option>
                        {
                            statusList && statusList.length > 0 && statusList.map((item, index) => {
                                return <option key={index} value={item}>{item}</option>
                            })
                        }
                    </select>
                    <div className='col-3 d-flex gap-5'>
                        <button className='btn bg-left-navabr  text-light '
                            onClick={handleSearch}>
                            Search
                        </button>
                        <button className='btn bg-left-navabr  text-light'
                            onClick={handleReset}
                        >
                            Reset</button>
                    </div>
                </div>
            </div>
            <div>
                <div className='box_overflow col'>
                    {isComplainListLoading && (
                        <p>Loading...</p>
                    )}

                    {isComplainListError && (
                        <p>Error: {complainListError}</p>
                    )}

                    {!isComplainListLoading && !isComplainListError && (
                        <table className='border table p-2'>
                            <thead className='mobile-hidden'>
                                <tr className='bg-left-navabr '>
                                    <th className='text-light'>#</th>
                                    <th className='text-light'>Name</th>
                                    <th className='text-light'>Number</th>
                                    <th className='text-light'>Complain</th>
                                    <th className='text-light'>Status</th>
                                </tr>
                            </thead>
                            <tbody className='mobile-hidden'>
                                {Object.values(complainList?.entities).map((complaint, index) => (
                                    <tr className='listData' key={complaint.c_id}
                                        onClick={() => (handleNavigateToDetail(complaint.c_id))}
                                    >
                                        {/* {
                                            console.log(complaint)
                                            
                                        } */}
                                        <td>{index + 1}</td>
                                        <td >{complaint.name}</td>
                                        <td>{complaint.number}</td>
                                        <td>{complaint.text.slice(0, 13) + '...'}</td>
                                        <td>{complaint.c_status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    {!isComplainListLoading && !isComplainListError && (
                        <div className='m-25 complain_box'>
                            {Object.values(complainList?.entities).map((complaint, index) => (
                                <div className="Card_table_data" key={complaint.c_id} onClick={() => (handleNavigateToDetail(complaint.c_id))}>
                                    <div div className="Card_data">
                                        <span className="Card_srNo">{index + 1}</span>
                                    </div>
                                    <div className="Card">
                                        <div className="Card_data">
                                            <span className="Card_srNo">Name :-</span>
                                            <span className="Card_data_list">{complaint.name}</span>
                                        </div>
                                        <div className="Card_data">
                                            <span className="Card_srNo">Number :-</span>
                                            <span className="Card_data_list Card_number">{complaint.number}</span>
                                        </div>
                                    </div>
                                    <div className="Card">
                                        <div className="Card_data">
                                            <span className="Card_srNo">Complain :-</span>
                                            <span className="Card_data_list">{complaint.text.slice(0, 13) + '...'}</span>
                                        </div>
                                        <div className="Card_data">
                                            <span className="Card_srNo">Status :-</span>
                                            <span className="Card_data_list">{complaint.c_status}</span>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    )}
                </div>

            </div>

        </div>
    );
}

export default ComplainList;
