import { useGetIECPersonQuery } from './iecPersonSlice';

const IecExcerpt = ({ incID, index }) => {
    const { inc } = useGetIECPersonQuery('getIECPerson', {
        selectFromResult: ({ data }) => ({
            inc: data?.entities[incID]
        }),
    })
    return (
        <>
            <tr key={index} className="listData mobile-hidden text-left fw-normal">
                <td>{index + 1}</td>
                <td>{inc?.inc_name}</td>
                <td>{inc?.inc_number}</td>
            </tr>
            <div key={index} className="Card_table_data">
                <div div className="Card_data">
                    <span className="Card_srNo">{index + 1}</span>
                </div>
                <div className="Card">
                    <div className="Card_data">
                        <span className="Card_srNo">Name :-</span>
                        <span className="Card_data_list">{inc?.inc_name}</span>
                    </div>
                    <div className="Card_data">
                        <span className="Card_srNo">Number :-</span>
                        <span className="Card_data_list">{inc?.inc_number}</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IecExcerpt