import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";



export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({

        addNewcollectionroute: builder.mutation({
            query: payLoad => ({
                url: `/b2g/eco-admin/create-collection-route?geo_id=${payLoad?.geo_id}&assignment_id=${payLoad?.assignment_id}`,
                method: 'POST',
            }),
            // invalidatesTags: [
            //     { type: 'B2gDriver', id: 'collectionroute' }
            // ]
        }),


    })
})

export const {
    useAddNewcollectionrouteMutation,
} = extendedApiSlice