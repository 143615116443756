import { useDeletehelperMutation } from "./wardSlice";
import swal from "sweetalert";

const HelperWardExcerpt = ({ editCommunity, Helper, index }) => {
    const [DeleteHelperData] = useDeletehelperMutation();

    const deleteHandle = async (helper_id) => {
        try {
            await DeleteHelperData(helper_id).unwrap();
            swal("Done", "Data Deleted Successfully", "success")
        } catch (error) {
            swal("Error", "Failed to delete the  data", "")
        }
    };
    return (
        <>
            {/* responsive for pc size */}
            <tr key={index} className="listData mobile-hidden text-left fw-normal">
                <td>{index + 1}</td>
                <td>{Helper?.helper_name}</td>
                <td>{Helper?.helper_number}</td>
                <td>{Helper?.h_leave == 0 ? "No" : "yes"}</td>
                <td>{Helper?.h_blocked == 0 ? "No" : "yes"}</td>
                <td>
                    <div className="dropdown">
                        <button
                            className="btn dropdown-toggle p-2"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{ border: 'none' }}
                        >
                            <i className="fas fa-ellipsis-v" style={{ color: 'gray' }}></i>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <div
                                    className="dropdown-item"
                                    onClick={() => editCommunity(Helper?.helper_id)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Edit
                                </div>
                                <div
                                    className="dropdown-item"
                                    onClick={() => deleteHandle(Helper?.helper_id)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Delete
                                </div>
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>

            {/* responsive for mobile size */}
            <div key={index} className="Card_table_data">
                <div div className="Card_data">
                    {/* <span className="Card_srNo">Sr. No.</span> */}
                    <span className="Card_srNo">{index + 1}</span>
                </div>
                <div className="Card">
                    <div className="Card_data">
                        <span className="Card_srNo">helper Name :-</span>
                        <span className="Card_data_list">{Helper?.helper_name}</span>
                    </div>
                    <div className="Card_data">
                        <span className="Card_srNo">Number :-</span>
                        <span className="Card_data_list">{Helper?.helper_number}</span>
                    </div>

                </div>
                <div className="Card">
                    <div className="Card_data">
                        <span className="Card_srNo">Leave :-</span>
                        <span className="Card_data_list">{Helper?.h_leave == 0 ? "No" : "yes"}</span>
                    </div>
                    <div className="Card_data">
                        <span className="Card_srNo">Blocked :-</span>
                        <span className="Card_data_list">{Helper?.h_blocked == 0 ? "No" : "yes"}</span>
                    </div>

                </div>
                <div className="Card">
                    <span className="Card_srNo">Action :-</span>
                    <span className="Card_data_list">
                        <div className="dropdown">
                            <button
                                className="btn dropdown-toggle p-2"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style={{ border: 'none' }}
                            >
                                <i className="fas fa-ellipsis-v" style={{ color: 'gray' }}></i>
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <div
                                        className="dropdown-item"
                                        onClick={() => editCommunity(Helper?.helper_id)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Edit
                                    </div>
                                    <div
                                        className="dropdown-item"
                                        onClick={() => deleteHandle(Helper?.helper_id)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Delete
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </span>
                </div>
            </div>
        </>
    );
};

export default HelperWardExcerpt;
