import { useNavigate } from "react-router-dom";
import { useState } from "react";
import SupervisorExcerpt from "./SupervisorExcerpt";
import SpinnerForPage from "../../components/spinner/SpinnerForPage";
import AddSupervisorOffCanavas from "./addSupervisor";
import { useGetSupervisorQuery } from "./supervisorSlice";
const Supervisors = () => {
    const {
        data: supervisors,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetSupervisorQuery('getSupervisor')


    const navigate = useNavigate()
    if (isError && error.message === '401') navigate('/b2g:login')

    const [filterSearch, setFilterSearch] = useState({})
    const [filterSupervisor, setFilterSupervisor] = useState()

    const searchHandle = (e) => {
        setFilterSearch({ ...filterSearch, [e.target.name]: e.target.value })
    }

    const filterSearchData = () => {
        const filteredData = supervisors?.ids?.filter((supervisor_id) => {
            const supervisorEntity = supervisors?.entities[supervisor_id];
            return (
                (!filterSearch?.ward_number || supervisorEntity?.ward_number?.includes(filterSearch?.ward_number)) &&
                (!filterSearch?.number || supervisorEntity?.number?.includes(filterSearch?.number)) &&
                (!filterSearch?.name || supervisorEntity?.name?.toLowerCase().includes(filterSearch?.name?.toLowerCase()))
            );
        });
        setFilterSupervisor(filteredData);
    }


    return (
        <>
            {isLoading && <SpinnerForPage />}
            <div className="container-fluid mt-5">
                <div className="d-flex justify-content-end mb-3">
                    <div className="text-right">
                        <AddSupervisorOffCanavas />
                    </div>
                </div>
                <div className="Card_table_data w-25">
                    <button type="button" className="Add_button bg-left-navabr w-100 text-light  btn btn-secondary" data-bs-toggle="offcanvas" data-bs-target="#depOffsearch" aria-controls="depOffsearch"> Search</button>

                    <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="1" id="depOffsearch" aria-labelledby="depOffsearch">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvas1Label">Search</h5>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ></button>
                        </div>
                        <input className="form-select mt-2" onChange={searchHandle} name="name" type="text" placeholder="Search Name" />
                        <input className="form-select mt-2" onChange={searchHandle} name="number" type="Number" placeholder="Search Number" />
                        <input className="form-select mt-2" onChange={searchHandle} name="ward_number" type="name" placeholder="Search Ward Number" />
                        <button className="bg-left-navabr mt-2 text-light  btn btn-secondary" onClick={filterSearchData} data-bs-dismiss="offcanvas" aria-label="Close" >Data Search</button>
                    </div>
                </div>
                <div className="mobile-hidden w-100 d-flex justify-content-center gap-5">
                    <input className="form-select w-25" onChange={searchHandle} name="name" type="text" placeholder="Search Name" />
                    <input className="form-select w-25" onChange={searchHandle} name="number" type="Number" placeholder="Search Number" />
                    <input className="form-select w-25" onChange={searchHandle} name="ward_number" type="name" placeholder="Search Ward Number" />
                    <button className="bg-left-navabr  text-light  btn btn-secondary" onClick={filterSearchData} >Data Search</button>
                </div>

                <div className="card card-dark mt-4 sidebarhome">
                    <div className="table-border-style">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr className="mobile-hidden text-left">
                                        <th scope="col">Sr. No.</th>
                                        <th scope="col">Name</th>
                                        <th scope="col"> Number</th>
                                        <th scope="col">Ward  Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        filterSupervisor?.map((supervisorid, index) => {
                                            return <SupervisorExcerpt key={index} supervisorid={supervisorid} index={index} />
                                        })
                                    }
                                    {filterSupervisor == null &&
                                        supervisors?.ids?.map((supervisorid, index) => {
                                            return <SupervisorExcerpt key={index} supervisorid={supervisorid} index={index} />
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading && <SpinnerForPage />}

        </>
    )
}

export default Supervisors;