import { createSlice } from "@reduxjs/toolkit";

const initialState = { page: 1 }

const DailyReportDatePageSlice = createSlice({
    name: 'DailyReportdateB2g',
    initialState,
    reducers: {
        setPageGlobal(state, action) {
            state.page = action.payload;
        },
    }
})

export const { setPageGlobal } = DailyReportDatePageSlice.actions
export default DailyReportDatePageSlice.reducer