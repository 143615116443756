import { useGetSupervisorQuery } from "./supervisorSlice";

const SupervisorExcerpt = ({ supervisorid, index }) => {
    const { supervisor } = useGetSupervisorQuery('getSupervisor', {
        selectFromResult: ({ data }) => ({
            supervisor: data?.entities[supervisorid]
        }),
    })

    return (
        <>
            {/* responsive for pc size */}
            <tr key={index} className="mobile-hidden listData text-left fw-normal">
                <td>{index + 1}</td>
                <td>{supervisor?.name}</td>
                <td>{supervisor?.number}</td>
                <td>{supervisor?.ward_number}</td>
            </tr>

            {/* responsive for mobile size */}
            <div key={index} className="Card_table_data">
                <div div className="Card_data">
                    <span className="Card_srNo">{index + 1}</span>
                </div>
                <div className="Card">
                    <div className="Card_data">
                        <span className="Card_srNo">Name :-</span>
                        <span className="Card_data_list">{supervisor?.name}</span>
                    </div>
                    <div className="Card_data">
                        <span className="Card_srNo">Number:-</span>
                        <span className="Card_data_list">{supervisor?.number}</span>
                    </div>
                </div>
                <div className="Card">
                    <div className="Card_data">
                        <span className="Card_srNo">Ward Number:-</span>
                        <span className="Card_data_list">{supervisor?.ward_number}</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SupervisorExcerpt