import React from "react";
import GoogleMapReact from "google-map-react";
import { useLocation } from "react-router-dom";

const Marker = ({ text }) => (
    <h1
        style={{
            padding: "8px 12px",
            borderRadius: "50%",
            textAlign: "center",
            fontWeight: "bold",
            transform: "translate(-50%, -50%)",
            width: "50px",
        }}
    >
        {/* 📍 */}
        <i class="fa-solid fa-location-dot"></i>
    </h1>
);

const SurveyMap = () => {
    const MAP_API_KEY = "AIzaSyBqbSNw8jtcU9MjyqXFgKdP9l9UeWqaTz8";
    const jaipurCoords = { lat: 26.9122, lng: 75.7872 };

    const location = useLocation().state;
    const center = {
        lat: location.location.x,
        lng: location.location.y
    }

    return (
        <div style={{ height: "90%", width: "90%", margin: "auto" }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: MAP_API_KEY }}
                defaultCenter={jaipurCoords}
                defaultZoom={13.7}
            >
                <Marker lat={center.lat} lng={center.lng} text="Jaipur" />
            </GoogleMapReact>
        </div>
    );
};

export default SurveyMap;
