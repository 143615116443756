import swal from "sweetalert"
import moment from "moment";
import { useDeleteEventManagerMutation, useGetEventManagerQuery } from "./EventManagerSlice";


const EventManagerExcerpt = ({ id, index, editEventManager }) => {

    const { EventManager } = useGetEventManagerQuery('getEventManagerData', {
        selectFromResult: ({ data }) => ({
            EventManager: data?.entities[id]
        }),
    })

    const [deleteEventManager] = useDeleteEventManagerMutation();

    const deleteHandle = async () => {
        try {
            await deleteEventManager({ id: id }).unwrap();
            swal("Done", "Data Deleted Successfully", "success")

        } catch (error) {
            console.error('Failed to delete the dailyWaste: ', error);
            swal("Error", "Failed to delete the dailyWaste", "Error")
        }
    };

    return (
        <>

            <tr className="mobile-hidden text-center fw-normal" key={index}>
                <td>{index + 1}</td>
                <td> {EventManager?.name} </td>
                <td className="fw-bold">{EventManager?.number}</td>

                <td>
                    <div className="dropdown">
                        <div className="nav-link active dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Action
                        </div>
                        <ul className="dropdown-menu">
                            <li>
                                <button className="dropdown-item" onClick={() => { editEventManager(EventManager) }}  >
                                    Edit
                                </button>
                            </li>
                            <li>
                                <button className="dropdown-item" onClick={deleteHandle}>
                                    Delete
                                </button>
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>
            <div className="Card_table_data">
                <div div className="Card_data">
                    <span className="Card_srNo">{index + 1}</span>
                </div>
                <div className="Card">
                    <div className="Card_data">
                        <span className="Card_srNo">Name :-</span>
                        <span className="Card_data_list">{EventManager?.name}</span>
                    </div>
                    <div className="Card_data">
                        <span className="Card_srNo">Number :-</span>
                        <span className="Card_data_list">{EventManager?.number}</span>
                    </div>
                </div>
                <div className="Card">
                    <div className="Card_data">
                        <span className="Card_srNo">Action :-</span>
                        <span className="Card_data_list"> <div className="dropdown">
                            <div className="nav-link active dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Action
                            </div>
                            <ul className="dropdown-menu">
                                <li>
                                    <button className="dropdown-item" onClick={() => { editEventManager(EventManager) }}  >
                                        Edit
                                    </button>
                                </li>
                                <li>
                                    <button className="dropdown-item" onClick={deleteHandle}>
                                        Delete
                                    </button>
                                </li>
                            </ul>
                        </div></span>
                    </div>
                </div>


            </div>
        </>
    );
};

export default EventManagerExcerpt;
