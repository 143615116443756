import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import Avatar from "react-avatar";
import secureLocalStorage from "react-secure-storage";
import { useGetProfileQuery } from '../../profile/profileSlice';
import { logout } from "../../auth/authSlice";
import './Navbar.css';
import { IoReorderThree } from "react-icons/io5";
import ecowraplogo from '../../../images/ecowrap-logo.png';

const SideBarProfile = ({ sidebarbuttonData, setSidebarbuttonData, }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [profileData, setProfileData] = useState({ name: "", role_type: "" })
    const handleLogout = () => {
        dispatch(logout())
    }

    const {
        data: profile,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetProfileQuery('getProfile')

    useEffect(() => {
        if (isSuccess && profile && profile.ids && profile.ids.length > 0) {
            const firstProfileId = profile.ids[0];
            const firstProfile = profile.entities[firstProfileId];
            setProfileData({ name: firstProfile.name, role_type: firstProfile.role_type });
        }
    }, [isSuccess, profile])

    // const SidebarButton = (e) => {
    //     sidebarbuttonData ? setSidebarbuttonData(false) : setSidebarbuttonData(true)
    // }

    return (
        <>
            <div className="sidebarProfile m-2 mt-2">
                <button className="btn  " onClick={() => navigate("/b2g/profile")}>
                    <i className="text-light fa-solid fa-user "></i> &nbsp;
                    <span className="text-light">Profile</span>
                </button>

                <button className="btn " onClick={handleLogout}>
                    <i className="text-light fa-solid fa-right-from-bracket"></i>
                    <span className="text-light">
                        &nbsp;   Logout </span>
                </button>
            </div>
        </>
    )
}

export default SideBarProfile;