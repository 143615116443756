import React from "react";
import './SpinnerForPage.css'

const SpinnerForPage = () => {
    return (
        <>
            <div className="loader_home">
                <div className="loader"></div>
            </div>

        </>
    )
}

export default SpinnerForPage;