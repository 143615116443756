import swal from "sweetalert"
import moment from "moment";
import { useDeleteEventDustbinMutation, useGetEventDustbinQuery } from "./EventDustbinSlice";


const EventDustbinExcerpt = ({ id, index, editEventDustbin }) => {

    const { EventDustbin } = useGetEventDustbinQuery('getEventDustbinData', {
        selectFromResult: ({ data }) => ({
            EventDustbin: data?.entities[id]
        }),
    })
    const [deleteEventDustbin] = useDeleteEventDustbinMutation();

    const deleteHandle = async () => {
        try {
            await deleteEventDustbin({ id: id }).unwrap();
            swal("Done", "Data Deleted Successfully", "success")
        } catch (error) {
            console.error('Failed to delete the dailyWaste: ', error);
            swal("Error", "Failed to delete the dailyWaste", "Error")
        }
    };

    return (
        <>
            <tr className="mobile-hidden text-center fw-normal" key={index}>
                <td>{index + 1}</td>
                <td> {EventDustbin?.name} </td>
                <td className="fw-bold">{moment(EventDustbin?.created_at).format("YYYY-MM-DD").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}</td>

                <td>
                    <div className="dropdown">
                        <div className="nav-link active dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Action
                        </div>
                        <ul className="dropdown-menu">
                            <li>
                                <button className="dropdown-item" onClick={() => { editEventDustbin(EventDustbin) }}  >
                                    Edit
                                </button>
                            </li>
                            <li>
                                <button className="dropdown-item" onClick={deleteHandle}>
                                    Delete
                                </button>
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>
            <div className="Card_table_data">
                <div div className="Card_data">
                    {/* <span className="Card_srNo">Sr. No.</span> */}
                    <span className="Card_srNo">{index + 1}</span>
                </div>
                <div className="Card">
                    <div className="Card_data">
                        <span className="Card_srNo">Name :-</span>
                        <span className="Card_data_list">{EventDustbin?.name}</span>
                    </div>
                    <div className="Card_data">
                        <span className="Card_srNo">Created Date :-</span>
                        <span className="Card_data_list">{moment(EventDustbin?.created_at).format("YYYY-MM-DD").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}</span>
                    </div>
                </div>
                <div className="Card">
                    <div className="Card_data">
                        <span className="Card_srNo">Action :-</span>
                        <span className="Card_data_list"><td>
                            <div className="dropdown">
                                <div className="nav-link active dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Action
                                </div>
                                <ul className="dropdown-menu">
                                    <li>
                                        <button className="dropdown-item" onClick={() => { editEventDustbin(EventDustbin) }}  >
                                            Edit
                                        </button>
                                    </li>
                                    <li>
                                        <button className="dropdown-item" onClick={deleteHandle}>
                                            Delete
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </td></span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventDustbinExcerpt;
