import swal from "sweetalert"
import { useDeleteDailyWasteMutation } from "./dailyWasteSlice";
import moment from "moment";


const DailyWasteExcerpt = ({ page, dailyWaste, index, editDailyWaste, subCategory }) => {

    let deleteId = dailyWaste?.w_id;
    const [deleteDailyWaste] = useDeleteDailyWasteMutation({ deleteId });

    const deleteHandle = async ({ id }) => {

        try {

            await deleteDailyWaste({ id: id }).unwrap();
            swal("Done", "Data Deleted Successfully", "success")

        } catch (error) {
            console.error('Failed to delete the dailyWaste: ', error);
            swal("Error", "Failed to delete the dailyWaste", "Error")
        }
    };

    let wasteData = [];
    if (dailyWaste) {
        wasteData = JSON.parse(dailyWaste?.w_waste);

    }
    const wasteDataShow = subCategory?.ids?.map((ids, i) => {
        const matchingWaste = wasteData.find(([wasteId]) => wasteId === ids) || [];

        if (!matchingWaste[1]) { return }
        return (
            <>
                <div key={index} className="d-flex justify-content-center flex-column">
                    {`${subCategory?.entities[ids]?.wsc_name}   - ${matchingWaste ? matchingWaste[1] : 0}${subCategory?.entities[ids]?.wsc_measure_unit}`}
                </div>
            </>

        );
    });


    let kg = 0;
    let litre = 0;
    let box = 0;

    subCategory?.ids?.map((id, i) => {
        const matchingWaste = wasteData.find(([wasteId]) => wasteId === id);
        if (subCategory?.entities[id]?.wsc_measure_unit === 'kg') {
            kg += matchingWaste ? matchingWaste[1] : 0

        } else if (subCategory?.entities[id]?.wsc_measure_unit === 'ton') {

            kg += (matchingWaste ? matchingWaste[1] : 0) * 1000

        } else if (subCategory?.entities[id]?.wsc_measure_unit === 'litre') {
            litre += matchingWaste ? matchingWaste[1] : 0
        } else if (subCategory?.entities[id]?.wsc_measure_unit === 'box') {
            box += matchingWaste ? matchingWaste[1] : 0

        }
    });

    return (
        <>
            {/* pc size code start   */}
            <tr className="mobile-hidden text-center fw-normal" key={index}>
                <td>{index + ((page * 10) - 9)}</td>

                <td>
                    {moment(dailyWaste?.w_collection_date).format("DD/MM/YYYY  ").toLocaleString()}
                </td>
                <td className="fw-bold">{wasteDataShow}</td>
                <td className="fw-bold  ">
                    {kg !== 0 && <p>kg : {kg}  </p>}
                    {litre !== 0 && <p>Litre : {litre} </p>}
                    {box !== 0 && <p>Box : {box} </p>}
                </td>

                <td>
                    {moment(dailyWaste?.w_created_at).format("DD/MM/YYYY").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}
                </td>
                <td>
                    <div className="dropdown">
                        <div className="nav-link active dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Action
                        </div>
                        <ul className="dropdown-menu">
                            <li>
                                <button className="dropdown-item" onClick={() => { editDailyWaste(index) }}  >
                                    Edit
                                </button>
                            </li>
                            <li>
                                <button className="dropdown-item" onClick={() => { deleteHandle({ id: dailyWaste?.w_id }) }}>
                                    Delete
                                </button>
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>

            {/* mobile size code   */}
            <div key={index} className="Card_table_data">
                <div div className="Card_data">
                    <span className="Card_srNo">{index + ((page * 10) - 9)}</span>
                </div>
                <div className="Card">
                    <div className="Card_data">
                        <span className="Card_srNo">Collection Date	 :-</span>
                        <span className="Card_data_list"> {moment(dailyWaste?.w_collection_date).format("DD/MM/YYYY  ").toLocaleString()}</span>
                    </div>
                    <div className="Card_data">
                        <span className="Card_srNo">Waste :-</span>
                        <span className="Card_data_list">{wasteDataShow}</span>
                    </div>
                </div>
                <div className="Card">
                    <div className="Card_data">
                        <span className="Card_srNo">Total Waste	:-</span>
                        <span className="Card_data_list">
                            {kg !== 0 && <p>kg : {kg}  </p>}
                            {litre !== 0 && <p>Litre : {litre} </p>}
                            {box !== 0 && <p>Box : {box} </p>}
                        </span>
                    </div>
                    <div className="Card_data">
                        <span className="Card_srNo">Creation Date:-</span>
                        <span className="Card_data_list">{moment(dailyWaste?.w_created_at).format("DD/MM/YYYY").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}</span>
                    </div>
                    <div className="Card_data">
                        <span className="Card_srNo">Action:-</span>
                        <span className="Card_data_list">
                            <div className="dropdown">
                                <div className="nav-link active dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Action
                                </div>
                                <ul className="dropdown-menu">
                                    <li>
                                        <button className="dropdown-item" onClick={() => { editDailyWaste(index) }}  >
                                            Edit
                                        </button>
                                    </li>
                                    <li>
                                        <button className="dropdown-item" onClick={() => { deleteHandle({ id: dailyWaste?.w_id }) }}>
                                            Delete
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DailyWasteExcerpt;
