import { useGetdriverQuery } from "./wardSlice";
import { useRef, useState } from "react";
import DriverWardExcerpt from "./DriverWardExcerpt";
import EditDriverWard from "./EditDriverWard";
import AddWardDriver from "./AddWardDriver";

const DriverWard = ({ urlB2G, wardData }) => {
    const [wardId, setWardId] = useState(wardData.wardId);
    const [editDriver, setEditDriver] = useState({})

    const refOpen = useRef(null);

    const {
        data: Driver,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetdriverQuery(wardId);

    const editDriverdata = (driverId) => {
        refOpen.current.click()
        console.log(driverId, "incId");
        const data = Driver?.entities[driverId]
        setEditDriver({
            p_id: data?.p_id,
            p_name: data?.p_name,
            p_number: data?.p_number,
            is_leave: data?.is_leave,
            is_blocked: data?.is_blocked,
            p_deleted: data?.p_deleted,
            p_ward_id: data?.p_ward_id
        })
    }


    return (
        <>
            <div className="container-fluid mt-5">
                <div className="text-right">
                    <AddWardDriver />
                </div>
                <div className="card card-dark mt-4">
                    <div className="table-border-style">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead className="mobile-hidden">
                                    <tr className=" text-left">
                                        <th scope="col">Sr. No.</th>
                                        <th scope="col">Driver Name</th>
                                        <th scope="col">Driver Number</th>
                                        <th scope="col">Leave</th>
                                        <th scope="col">Blocked</th>
                                        <th scope="col">Deleted</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        Driver?.ids?.map((Id, index) => {
                                            return <DriverWardExcerpt key={index} driver={Driver?.entities[Id]} index={index} editDriverdata={editDriverdata}
                                            />
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <EditDriverWard refOpen={refOpen} editDriver={editDriver} setEditDriver={setEditDriver} />
        </>
    )
}

export default DriverWard