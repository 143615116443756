import React, { useState, useRef } from "react";
import { useGetEventDustbinQuery } from "./EventDustbinSlice";
import EditEventDustbin from "./EditEventDustbin";
import AddEventDustbin from "./AddEventDustbin";
import EventDustbinExcerpt from "./EventDustbinExcerpt";

const EventDustbin = () => {

    const refOpen = useRef(null)
    const [editEventDustbins, setEditEventDustbins] = useState({ name: "", number: "" });

    const {
        data: EventDustbin,
        isError
    } = useGetEventDustbinQuery('getEventDustbin')

    const editEventDustbin = (EventDustbin) => {
        refOpen.current.click()
        setEditEventDustbins({ name: EventDustbin.name, number: EventDustbin.number, id: EventDustbin.id });
    }

    return (
        <>
            {isError && <div>Something went wrong</div>}
            {EventDustbin &&
                <div className="container-fluid mt-5" >
                    <div className="d-flex justify-content-end">
                        <div className="text-right ">
                            <AddEventDustbin />
                        </div>
                    </div>

                    <div className="card card-dark mt-4" style={{ height: "50%" }}>
                        {
                            (
                                <div className="table-border-style">
                                    <div className="table-responsive sidebarhome ">
                                        <table className="table table-striped">
                                            <thead className="mobile-hidden">
                                                <tr className="text-center">
                                                    <th scope="col">Sr. No.</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Created Date</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    EventDustbin?.ids?.map((id, index) => {
                                                        return (
                                                            <EventDustbinExcerpt key={index} id={id} index={index} editEventDustbin={editEventDustbin} />
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            }

            <EditEventDustbin refOpen={refOpen} editEventDustbins={editEventDustbins} setEditEventDustbins={setEditEventDustbins} />

        </>
    )
}

export default EventDustbin;