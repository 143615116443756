import React, { useRef, useState } from 'react';
import { useGetDriversQuery } from "./driverSlice";
import DriverExcerpt from './DriverExcerpt';
import SpinnerForPage from '../../components/spinner/SpinnerForPage';
import AddDriver from './addDriver';
import DriverEdit from './DriverEdit';

const CollectionDriver = () => {
    const {
        data: drivers,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetDriversQuery('getDrivers')

    const [filterSearch, setFilterSearch] = useState({})
    const [filterDriver, setFilterDriver] = useState()
    const [editData, setEditData] = useState({});
    const refOpen = useRef();


    const searchHandle = (e) => {
        setFilterSearch({ ...filterSearch, [e.target.name]: e.target.value })
    }

    const filterSearchData = () => {
        const filteredData = drivers?.ids?.filter((supervisor_id) => {
            const supervisorEntity = drivers?.entities[supervisor_id];
            return (
                (!filterSearch?.role_type || supervisorEntity?.role_type?.includes(filterSearch?.role_type)) &&
                (!filterSearch?.number || supervisorEntity?.number?.includes(filterSearch?.number)) &&
                (!filterSearch?.name || supervisorEntity?.name?.toLowerCase().includes(filterSearch?.name?.toLowerCase()))
            );
        });
        setFilterDriver(filteredData);
    }

    const editCommunity = (Id) => {
        refOpen.current.click()
        const data = drivers?.entities[Id];
        setEditData({ p_name: data?.p_name, p_email: data?.p_email, is_leave: data?.is_leave, is_blocked: data?.is_blocked, p_ward_id: data?.p_ward_id })
    }

    return (
        <div className="container-fluid mt-5">
            {isLoading && <SpinnerForPage />}
            <div className="text-right mb-3">
                <AddDriver />
            </div>
            <div className="Card_table_data w-25">
                <button type="button" className="Add_button bg-left-navabr w-100 text-light  btn btn-secondary" data-bs-toggle="offcanvas" data-bs-target="#depOffsearch" aria-controls="depOffsearch"> Search</button>
                <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="1" id="depOffsearch" aria-labelledby="depOffsearch">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvas1Label">Search</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ></button>
                    </div>
                    <input className="form-select mt-2" onChange={searchHandle} name="name" type="text" placeholder="Search Name" />
                    <input className="form-select mt-2" onChange={searchHandle} name="number" type="Number" placeholder="Search Number" />
                    <input className="form-select mt-2" onChange={searchHandle} name="role_type" type="name" placeholder="Role" />
                    <button className="bg-left-navabr mt-2 text-light  btn btn-secondary" onClick={filterSearchData} data-bs-dismiss="offcanvas" aria-label="Close" >Data Search</button>
                </div>
            </div>

            <div className="mobile-hidden w-100 d-flex justify-content-center gap-5">
                <input className="form-select w-25" onChange={searchHandle} name="name" type="text" placeholder="Search Name" />
                <input className="form-select w-25" onChange={searchHandle} name="number" type="Number" placeholder="Search Number" />
                <input className="form-select w-25" onChange={searchHandle} name="role_type" type="name" placeholder="Role" />
                <button className="bg-left-navabr  text-light  btn btn-secondary" onClick={filterSearchData}>Data Search</button>
            </div>

            <div className="card card-dark mt-4">
                <div className="table-border-style">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead className='mobile-hidden'>
                                <tr className="text-left">
                                    <th scope="col">Sr. No.</th>
                                    <th scope="col">Name</th>
                                    <th scope="col"> Number</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Leave</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filterDriver?.map((driverId, index) => {
                                    return <DriverExcerpt driverId={driverId} editCommunity={editCommunity} index={index} />
                                })
                                }
                                {filterDriver == null &&
                                    drivers?.ids?.map((driverId, index) => {
                                        return <DriverExcerpt driverId={driverId} editCommunity={editCommunity} index={index} />
                                    })
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <DriverEdit refOpen={refOpen} setEditData={setEditData} editData={editData} />
        </div>
    );
};

export default CollectionDriver;
