import { useLocation } from "react-router-dom";
import { useGetEventmanagerQuery, useManagerEventListDeleteMutation } from "./EventSlice";
import AddEventmanager from "./AddEventmanager";
import swal from "sweetalert";


const Eventmanager = () => {
    const propertyId = useLocation()?.state?.propertyId;
    const {
        data: eventmanager,
    } = useGetEventmanagerQuery(propertyId)



    const [managerEventListDelete] = useManagerEventListDeleteMutation();
    const handleClick = async (id) => {
        try {
            await managerEventListDelete(id).unwrap()
            swal("Success", "Data Delete")
        }
        catch (e) {
            console.error(e);
            swal("Error", "Failed to delete the dailyWaste", "Error")
        }
    }

    return (
        <div className="container-fluid mt-5">
            <div className="container-fluid mt-5">
                <div className="w-100 d-flex justify-content-end gap-5">
                    <div className="col-md-3 text-right">
                        <AddEventmanager propertyId={propertyId} />
                    </div>
                </div>

                <div className="card card-dark mt-4">
                    <div className="table-border-style">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead className="mobile-hidden">
                                    <tr className=" text-left">
                                        <th scope="col">Event Name</th>
                                        <th scope="col">Manager Name</th>
                                        <th scope="col">Manager Number</th>
                                        <th scope="col">Manager Delet</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        eventmanager?.entities.map((index) => {
                                            return <>
                                                <tr key={index} className="mobile-hidden listData mobile-hidden text-left fw-normal">
                                                    <td>{index.event_name}</td>
                                                    <td>{index.manager_name}</td>
                                                    <td>{index.manager_number}</td>

                                                    <td>
                                                        <button className="btn btn-outline-dark" onClick={() => handleClick(index?.manager_assign_id)}> Delet</button>
                                                    </td>
                                                </tr >
                                                <div key={index} className="Card_table_data">

                                                    <div className="Card">
                                                        <div className="Card_data">
                                                            <span className="Card_srNo">Event Name :-</span>
                                                            <span className="Card_data_list">{index.event_name}</span>
                                                        </div>
                                                        <div className="Card_data">
                                                            <span className="Card_srNo">Manager Name :-</span>
                                                            <span className="Card_data_list">{index.manager_name}</span>
                                                        </div>
                                                    </div>
                                                    <div className="Card">
                                                        <div className="Card_data">
                                                            <span className="Card_srNo">Manager Number :-</span>
                                                            <span className="Card_data_list">{index.manager_name}</span>
                                                        </div>
                                                        <div className="Card_data">
                                                            <span className="Card_srNo">Manager Delet :-</span>
                                                            <span className="Card_data_list">
                                                                <button onClick={() => handleClick(index?.manager_assign_id)}> Delet</button>
                                                            </span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div >
    );
};

export default Eventmanager;
